import { useEffect, useState } from "react";
import useCheckAuth from "../../hooks/useCheckAuth";
import { updateHomePage } from "../../utils/https/ContentManagement/Home/mutation";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchHomePageContent } from "../../utils/https/ContentManagement/Home/query";
import { queryClient } from "../../utils/https";

import HeroImage from "../../components/ContentManagement/Home/HeroImage";
import CallToAction from "../../components/ContentManagement/Home/CallToAction/CallToAction";
import IconContents from "../../components/ContentManagement/Home/IconContents/IconContents";
import Quote from "../../components/ContentManagement/Home/Quote";
import ActionButtons from "../../components/UI/Buttons/ActionButtons";

import styles from "./Home.module.scss";
import { Blocks as BlocksLoader } from "react-loader-spinner";
import HeroMessageSection from "../../components/ContentManagement/Home/HeroMessage";

export default function ContentManagementHome() {
  // Check if user is logged in. If not, redirect to login page.
  useCheckAuth();

  const {
    data: content,
    isPending: isQueryPending,
    isError: isQueryError,
    error: queryError,
  } = useQuery({
    queryKey: ["homepage-content"],
    queryFn: fetchHomePageContent,
  });

  const {
    mutate,
    isPending: isMutationPending,
    isError: isMutationError,
    error: mutationError,
  } = useMutation({
    mutationFn: updateHomePage,
    onSuccess: () => queryClient.invalidateQueries(["homepage-content"]),
  });

  // Set a state to keep track of the previous data, so that the form can be discarded.
  const [prevData, setPrevData] = useState(null);
  const [data, setData] = useState(null);

  const [lang, setLang] = useState("en");

  useEffect(() => {
    if (content) {
      setData(content);
      setPrevData(content);
    }
  }, [content]);

  const submitHandler = async () => {
    mutate(data);
  };

  const discardHandler = () => {
    setData(prevData);
  };

  const actionButtons = [
    {
      text: "Discard",
      variant: "secondary",
      onClick: discardHandler,
      className: styles["action-button"],
    },
    {
      text: isMutationPending ? "Updating..." : "Update",
      variant: "primary",
      onClick: submitHandler,
      className: styles["action-button"],
    },
  ];

  if (isQueryPending || !data) {
    return (
      <BlocksLoader
        height="50"
        width="50"
        color="#27aae1"
        ariaLabel="blocks-loading"
        visible={true}
      />
    );
  }

  console.log(data);

  if (data) {
    return (
      <>
        <HeroImage image={data.heroImg} onUpdate={setData} />
        <HeroMessageSection
          heroMessage={data.lang[lang].heroMessage}
          lang={lang}
          onUpdate={setData}
        />
        <CallToAction
          CTA={data.lang[lang].cta}
          lang={lang}
          onUpdate={setData}
        />
        <IconContents
          iconContents={data.lang[lang].icons}
          lang={lang}
          onUpdate={setData}
        />
        <Quote quote={data.lang[lang].quote} lang={lang} onUpdate={setData} />

        <ActionButtons
          buttons={actionButtons}
          className={styles["action-buttons-flex-end"]}
        />

        {isMutationError && <p>{mutationError.message}</p>}
      </>
    );
  }
}
